
import {
	getRequest,
	postRequest
} from "@/http/http.js"

// 接收日志表格
export const sysMqttReceivingLogs = query => postRequest('/admin/sysMqttReceivingLogs', query);

// 发送日志表格
export const sysServeSendLogs = query => postRequest('/admin/sysServeSendLogs', query);





