<template>
	<div class="bigBox">
		<h2>数据接收日志</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="搜索名称/IEME编码"
								class="namePut"></el-input>
							<el-button type="primary" icon="Search" class="searchs" @click="searchs">
								搜索</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="tranList" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="company_name" label="公司名称" align="center">
					</el-table-column>
					<el-table-column prop="device_name" label="设备名称" align="center">
					</el-table-column>
					<el-table-column prop="device_ieme" label="IEME编码" align="center">
					</el-table-column>
					<el-table-column prop="create_time" label="传输时间" align="center">
					</el-table-column>
					<el-table-column prop="content" label="传输内容" align="center">
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="tranList.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		sysMqttReceivingLogs
	} from "@/api/dataTransmission/dataTransmission.js"
	export default {
		data() {
			return {
				form: {
					keyword: "",
					region1: "",
					region2: "",
				},
				tranList: [],
				pageNo: 1,
				total: 0,
			}
		},
		mounted() {
			this.getDataTranList()
		},
		methods: {
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getDataTranList()
			},
			// 接收日志表格
			async getDataTranList() {
				const res = await sysMqttReceivingLogs({
					keyword: this.form.keyword,
					page: this.pageNo,
				})
				this.tranList = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 搜索
			searchs() {
				this.getDataTranList()
			},
			// 重置
			resetting() {
				this.pageNo = 1;
				this.form.keyword = '';
				this.getDataTranList()
			}
		}

	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.namePut {
		/deep/.el-input__wrapper {
			width: 360px;
			height: 40px;
		}
	}

	.agains {
		padding: 0 31px !important;
		margin-left: 10px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>